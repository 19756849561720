<template>
  <div id="nfe" class="wh-container">
    <div class="header no-print">
      <p class="title">Nota Fiscal Eletrônica</p>

      <b-button
        variant="primary"
        @click="openNewNFe"
        class="header__button issue-nf__button"
      >
        <NewFeatureIcon class="icon" />
        Emitir nova NFS-e
      </b-button>
    </div>

    <v-tab :index="currentTabIndex" :keys="this.tabs" @onChange="onChangeTab">
      <router-view />
    </v-tab>

    <Onboarding />
    <NewInvoiceModal />
    <ResumeInvoiceModal />
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
  name: 'NFe',
  metaInfo: {
    title: 'Eyecare - Emissão de Notas Fiscais'
  },
  components: {
    'v-tab': () => import('@/components/Tab.vue'),
    NewFeatureIcon: () => import('@/assets/icons/new-feature.svg'),
    Onboarding: () => import('@/modules/nfe/components/Onboarding'),
    NewInvoiceModal: () => import('@/modules/nfe/modals/NewInvoiceModal'),
    ResumeInvoiceModal: () => import('@/modules/nfe/modals/ResumeInvoiceModal')
  },
  mounted() {
    Object.keys(this.tabKeys).map(key => {
      this.tabs.push(this.tabKeys[key])
    })
    this.handleTabIndex()
  },
  computed: {
    ...mapState('nfe', ['showCNPJForm', 'companies']),
  },
  data() {
    return {
      tabs: [],
      currentTabIndex: 0,
      clinic: getCurrentClinic(),
      tabKeys: {
        NFDash: 'Dashboard',
        NFFlu: 'Fluxo de NFS-e',
        NFCadas: 'Cadastros de CNPJ'
      }
    }
  },
  methods: {
    getPathByIndex(index) {
      const path = {
        0: 'dashboard',
        1: 'fluxo_de_nfe',
        2: 'cadastros'
      }
      return path[index]
    },
    onChangeTab(index) {
      this.currentTabIndex = index
      this.$store.dispatch('nfe/updateShowCNPJForm', false)
      this.$router.push(`/nota-fiscal/${this.getPathByIndex(index)}`)
    },
    handleTabIndex() {
      const routes = {
        dashboard: 0,
        fluxo_de_nfe: 1,
        cadastros: 2,
        default: 0
      }
      const pathnameParts = location.pathname.split('/')
      const path = pathnameParts[pathnameParts.length - 1]
      this.currentTabIndex = routes[path] || routes.default
    },
    async openNewNFe() {
      const isLoading = this.$loading.show()

      try {
        if(this.companies.length === 0) {
          await this.getCompanies(this.clinic.id)
        }

        if (this.companies.length) {
          this.$store.dispatch('nfe/updateNfModalData', {})
          this.$bvModal.show('new-invoice-modal')
        } else {
          this.$toast.error('Por favor, cadastre Prestadores para emitir Notas Fiscais.')
        }
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    ...mapActions('nfe', ['getCompanies'])
  }
}
</script>

<style lang="scss" scoped>
.wh-container {
  height: 100%;
  padding: 30px 24px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 24px;

    .header__button {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 16px;
      height: 18px;
      margin-right: 1rem;
    }
  }

  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
  }

  .wh-button {
    margin: 0 5px;
    color: var(--blue-500);
  }
}
</style>
